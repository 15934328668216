.jewellery-product {
  .MuiCard-root {
    .MuiCardMedia-img {
      transition: 0.6s all ease-in-out;

      &:hover {
        transform: scale(1.2);
      //   width: 300px;
      // height: 300px;
      }
    }
  }
}