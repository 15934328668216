@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 84% !important;
  /* font-family: 'Source Sans Pro', sans-serif !important; */
}

@font-face {
  font-family: "JewelFont";
  src: local("JewelFont"),
  url("./fonts/quicksand/Quicksand-Regular.ttf") format("truetype");
  /* url("./fonts/nugget/nuggetmed.ttf") format("truetype"); */
}

@font-face {
  font-family: "ForNumbers";
  src: local("ForNumbers"),
  url("./fonts/bahnschrift/BAHNSCHRIFT\ 1.TTF") format("truetype");
}