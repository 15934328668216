.App {
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.font-9{font-size: 9px;}
.font-10{font-size: 10px;}
.font-11{font-size: 11px;}
.font-12{font-size: 12px;}
.font-13{font-size: 13px;}
.font-14{font-size: 14px;}
.font-15{font-size: 15px;}
.font-16{font-size: 16px;}
.font-17{font-size: 17px;}
.font-18{font-size: 18px;}
.font-20{font-size: 20px;}
.font-22{font-size: 22px;}
.font-24{font-size: 24px;}
.font-26{font-size: 26px;}
.font-28{font-size: 28px;}
.font-30{font-size: 30px;}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.pointer{
  cursor: pointer;
}

.color-7c{
  color: #7c7c7c;
}
.color-6d{
  color: #6d6d6d;
}
.color-888{
  color: #888888;
}

.color-black{
  color: #000000;
}

.overflow-ui{
  overflow-x: hidden !important;
}

.pl-1{
  padding-left: 4px;
}

.pl-2{
  padding-left: 8px;
}

.pl-3{
  padding-left: 12px;
}

.pl-4{
  padding-left: 16px;
}

.pl-5{
  padding-left: 20px;
}

.pr-1{
  padding-right: 4px;
}

.pr-2{
  padding-right: 8px;
}

.pr-3{
  padding-right: 12px;
}

.pr-4{
  padding-right: 16px;
}

.pr-5{
  padding-right: 20px;
}

.px-1{
  padding-left: 4px;
  padding-right: 4px;
}

.px-2{
  padding-left: 8px;
  padding-right: 8px; 
}

.px-3{
  padding-left: 12px;
  padding-right: 12px;
}

.px-4{
  padding-left: 16px;
  padding-right: 16px;
}

.px-5{
  padding-left: 20px;
  padding-right: 20px;
}

.pt-1{
  padding-top: 4px;
}

.pt-2{
  padding-top: 8px;
}

.pt-3{
  padding-top: 12px;
}

.pt-4{
  padding-top: 16px;
}

.pt-5{
  padding-top: 20px;
}

.pb-1{
  padding-bottom: 4px;
}

.pb-2{
  padding-bottom: 8px;
}

.pb-3{
  padding-bottom: 12px;
}

.pb-4{
  padding-bottom: 16px;
}

.pb-5{
  padding-bottom: 20px;
}

.py-1{
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2{
  padding-top: 8px;
  padding-bottom: 8px; 
}

.py-3{
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-4{
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-5{
  padding-top: 20px;
  padding-bottom: 20px;
}

.d-flex{
  display: flex;
}

.font-weight-bold{
  font-weight: bold;
}

.font-weight-medium{
  font-weight: 600;
}

ul {
  padding: 15px;  
  li {
    padding-bottom: 5px;
  } 
}

.error {
  color: red;
  font-size: 14px;
  margin-left: 15px;
}

.jewel-price {
  font-family: "ForNumbers";
}

.fx-input {
  span{
    font-family: "JewelFont";
  }
}

.color-white {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
