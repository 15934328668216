.fx-button-group {
    padding: 4px;
    border: 1px solid #ccc;
    margin-right: 13px;
    width: 140px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  .fx-button-group-selected {
    color: #fff;
    font-weight: 500;    
  }